h1, h2 {
    color: white;
}

.transparent-style{
    background-color: rgba(255, 255, 255, .4);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.bg-powderblue{
    background-color:powderblue;
}

.sticky-nav {
    position: sticky;
    top: 0;
}


.SegmentedCtl {
    background-color: rgba(255, 255, 255, 0.1);
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    height: 50px;
}

.segmented-control > label:after {
    color:black;
}

.hero-jumbo h1 {
    margin-top: 50px;
}

.detail-view {
    background-color: rgba(255, 255, 255, .2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
}

.detail-img {
    width: 100%;
}

.detail-text {
    color: white;
    font-size: 18px;
}

.detail-caption {
    color:white;
    padding-top: 20px;
}

.footer {
    text-align: center;
    color: white;
    background-color: black;
}

#details {
    visibility: hidden;
    margin-top: -50px;
    margin-bottom: 0px;
    color: white;
}